import React, { lazy, useEffect, MouseEvent, useContext, useState, Suspense } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, ErrorMessage, FieldProps } from "formik";

import { calculateGeocode } from "./calculate-geocode";
import { DispatchContext } from "../../context";
import { Spinner, UploadArea } from "../../components";
import { StoreDto, StoreDtoWithImages } from "../../entities/store.dto";
import { storeValidationSchema } from "./store.validation";
import { InputText } from "primereact/inputtext";
import { Fieldset } from "primereact/fieldset";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Image } from "../../entities/image.entity";
import { Authorized } from "../../access-control/authorized";
import { WorkingDay } from "../../components/form-components/working-day/working-day.element";
import { storeBlueprint } from "./store.blueprint";
import { InputTextarea } from "primereact/inputtextarea";

type Props = {
    organisationId: string;
    id?: string;
    data: StoreDto;
    remove?: (e: MouseEvent<HTMLButtonElement>) => void;
    handleSubmit: (values: StoreDtoWithImages) => void;
    submitRedirect?: string;
    returnTo?: string | null;
};

const LazyMap = lazy(() => import("../../components/map/map"));

export const StoreForm: React.FC<Props> = ({
    id,
    remove,
    organisationId,
    data,
    handleSubmit,
    returnTo,
}): JSX.Element => {
    const [initialValues, setInitialValues] = useState({
        ...storeBlueprint,
        ...data,
    });
    const { dispatch } = useContext(DispatchContext);

    useEffect(() => {
        // setInitialValues(data);
        setInitialValues({
            ...storeBlueprint,
            ...data,
        });
    }, [data]);

    // function setImages(images: Image[]) {
    //     setInitialValues((state) => ({ ...state, images: images || null }));
    // }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={storeValidationSchema}
        >
            {({ handleSubmit, handleBlur, setFieldValue, errors, values, isSubmitting }) => {
                return (
                    <form className="p-fluid" onSubmit={handleSubmit}>
                        <div className="p-field">
                            <Field type="text" name="name">
                                {({ field, form }: FieldProps) => {
                                    return (
                                        <>
                                            <label htmlFor={field.name}>Filialname *</label>
                                            <ErrorMessage name={field.name}>
                                                {(msg) => <div className="form__error-message">{msg}</div>}
                                            </ErrorMessage>
                                            <InputText type="text" {...field} placeholder="Name (Pflichtfeld)" />
                                        </>
                                    );
                                }}
                            </Field>
                        </div>
                        <div className="p-field">
                            <Field name="location.coordinates[1]">
                                {({ field, form }: FieldProps) => <input type="hidden" {...field} />}
                            </Field>
                            <Field name="location.coordinates[0]">
                                {({ field, form }: FieldProps) => <input type="hidden" {...field} />}
                            </Field>
                            <Field type="text" name="address.street">
                                {({ field, form }: FieldProps) => {
                                    return (
                                        <>
                                            <label htmlFor={field.name}>Straße und Hausnr. *</label>
                                            <ErrorMessage name={field.name}>
                                                {(msg) => <div className="form__error-message">{msg}</div>}
                                            </ErrorMessage>
                                            <span>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder="Straße und Hausnr. (Pflichtfeld)"
                                                    {...field}
                                                    onBlur={(e) => {
                                                        calculateGeocode(form.values, setInitialValues, dispatch);
                                                        handleBlur(e);
                                                    }}
                                                />
                                            </span>
                                        </>
                                    );
                                }}
                            </Field>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <Field type="text" name="address.zip">
                                    {({ field, form }: FieldProps) => {
                                        return (
                                            <>
                                                <label htmlFor={field.name}>PLZ *</label>
                                                <ErrorMessage name={field.name}>
                                                    {(msg) => <div className="form__error-message">{msg}</div>}
                                                </ErrorMessage>
                                                <span>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder="PLZ (Pflichtfeld)"
                                                        {...field}
                                                        onBlur={(e) => {
                                                            calculateGeocode(form.values, setInitialValues, dispatch);
                                                            handleBlur(e);
                                                        }}
                                                    />
                                                </span>
                                            </>
                                        );
                                    }}
                                </Field>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <Field type="text" name="address.city">
                                    {({ field, form }: FieldProps) => {
                                        return (
                                            <>
                                                <label htmlFor={field.name}>Ort *</label>
                                                <ErrorMessage name={field.name}>
                                                    {(msg) => <div className="form__error-message">{msg}</div>}
                                                </ErrorMessage>
                                                <span>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder="Ort (Pflichtfeld)"
                                                        {...field}
                                                        onBlur={(e) => {
                                                            calculateGeocode(form.values, setInitialValues, dispatch);
                                                            handleBlur(e);
                                                        }}
                                                    />
                                                </span>
                                            </>
                                        );
                                    }}
                                </Field>
                            </div>
                        </div>
                        <div className="p-field">
                            <>
                                {values.location?.coordinates[1] && values.location?.coordinates[0] ? (
                                    <Suspense
                                        fallback={
                                            <div className="p-d-flex p-jc-center">
                                                <Spinner />
                                            </div>
                                        }
                                    >
                                        <LazyMap
                                            center={[
                                                values.location?.coordinates[1] as number,
                                                values.location?.coordinates[0] as number,
                                            ]}
                                            name="location.coordinates"
                                            popupContent={values.name}
                                            setFieldValue={setFieldValue}
                                        />
                                    </Suspense>
                                ) : (
                                    <div className="hint">
                                        Bitte geben Sie eine Adresse an
                                        <ErrorMessage name="location.coordinates">
                                            {(msg) => <div className="form__error-message">{msg[0] && msg[0]}</div>}
                                        </ErrorMessage>
                                    </div>
                                )}
                            </>
                        </div>
                        <div className="p-field">
                            <Field name="workingDays">
                                {({ field }: FieldProps) => (
                                    <Fieldset legend="Öffnungszeiten">
                                        <WorkingDay
                                            setFieldValue={setFieldValue}
                                            field={field}
                                            fieldName="workingDays.monday"
                                            workingDayLabel="Montag"
                                        />
                                        <WorkingDay
                                            setFieldValue={setFieldValue}
                                            field={field}
                                            fieldName="workingDays.tuesday"
                                            workingDayLabel="Dienstag"
                                        />
                                        <WorkingDay
                                            setFieldValue={setFieldValue}
                                            field={field}
                                            fieldName="workingDays.wednesday"
                                            workingDayLabel="Mittwoch"
                                        />
                                        <WorkingDay
                                            setFieldValue={setFieldValue}
                                            field={field}
                                            fieldName="workingDays.thursday"
                                            workingDayLabel="Donnerstag"
                                        />
                                        <WorkingDay
                                            setFieldValue={setFieldValue}
                                            field={field}
                                            fieldName="workingDays.friday"
                                            workingDayLabel="Freitag"
                                        />
                                        <WorkingDay
                                            setFieldValue={setFieldValue}
                                            field={field}
                                            fieldName="workingDays.saturday"
                                            workingDayLabel="Samstag"
                                        />
                                        <WorkingDay
                                            setFieldValue={setFieldValue}
                                            field={field}
                                            fieldName="workingDays.sunday"
                                            workingDayLabel="Sonntag"
                                        />
                                    </Fieldset>
                                )}
                            </Field>
                        </div>
                        <div className="p-field">
                            <Field type="text" name="description">
                                {({ field }: FieldProps): JSX.Element => {
                                    return (
                                        <>
                                            <label htmlFor={field.name}>Beschreibung</label>
                                            <InputTextarea rows={5} cols={30} {...field} autoResize />
                                        </>
                                    );
                                }}
                            </Field>
                        </div>
                        <div className="p-field">
                            <Fieldset legend="Service">
                                <Field name="services.deliveryService">
                                    {({ field, form }: FieldProps) => {
                                        return (
                                            <span className="p-field-checkbox">
                                                <Checkbox {...field} checked={field.value} id={`${field.name}`} />
                                                <label htmlFor={`${field.name}`}>Lieferservice</label>
                                            </span>
                                        );
                                    }}
                                </Field>
                                <Field name="services.takeAwayService">
                                    {({ field, form }: FieldProps) => {
                                        return (
                                            <span className="p-field-checkbox">
                                                <Checkbox {...field} checked={field.value} id={`${field.name}`} />
                                                <label htmlFor={`${field.name}`}>Abholung</label>
                                            </span>
                                        );
                                    }}
                                </Field>
                                <Field name="services.eatInService">
                                    {({ field, form }: FieldProps) => {
                                        return (
                                            <span className="p-field-checkbox">
                                                <Checkbox {...field} checked={field.value} id={`${field.name}`} />
                                                <label htmlFor={`${field.name}`}>Vorort essen</label>
                                            </span>
                                        );
                                    }}
                                </Field>
                            </Fieldset>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-6">
                                <Field type="text" name="phone">
                                    {({ field, form }: FieldProps) => {
                                        return (
                                            <>
                                                <label htmlFor={`${field.name}`}>Telefon *</label>
                                                <ErrorMessage name={field.name}>
                                                    {(msg) => <div className="form__error-message">{msg}</div>}
                                                </ErrorMessage>
                                                <InputText
                                                    {...field}
                                                    id={`${field.name}`}
                                                    placeholder="Telefon (Pflichtfeld)"
                                                />
                                            </>
                                        );
                                    }}
                                </Field>
                            </div>
                            <div className="p-field p-col-12 p-md-6">
                                <Field type="text" name="email">
                                    {({ field, form }: FieldProps) => {
                                        return (
                                            <>
                                                <label htmlFor={`${field.name}`}>E-Mail</label>
                                                <ErrorMessage name={field.name}>
                                                    {(msg) => <div className="form__error-message">{msg}</div>}
                                                </ErrorMessage>
                                                <span>
                                                    <InputText
                                                        {...field}
                                                        id={`${field.name}`}
                                                        autoComplete="off"
                                                        placeholder="E-Mail"
                                                    />
                                                </span>
                                            </>
                                        );
                                    }}
                                </Field>
                            </div>
                        </div>
                        <div className="p-field">
                            <Field type="text" name="website">
                                {({ field, form }: FieldProps) => {
                                    return (
                                        <>
                                            <label htmlFor={`${field.name}`}>Website</label>
                                            <InputText
                                                {...field}
                                                id={`${field.name}`}
                                                autoComplete="off"
                                                placeholder="Website"
                                            />
                                        </>
                                    );
                                }}
                            </Field>
                        </div>
                        <div className="p-field">
                            <>
                                <UploadArea
                                    multiple
                                    id={organisationId}
                                    fieldName="images"
                                    images={values.images}
                                    setImages={(images: Image[]) => {
                                        setFieldValue("images", images || null);
                                    }}
                                    chooseLabel={
                                        values.images && values.images?.length > 0
                                            ? `Andere Bilder auswählen`
                                            : `Bilder auswählen`
                                    }
                                />
                            </>
                        </div>
                        <div className="p-field p-d-flex p-jc-end">* Pflichtfeld</div>
                        <div className="p-d-flex actions">
                            <Link
                                to={returnTo ? returnTo : "/settings/organisations"}
                                className="p-button p-button-outlined p-button-secondary p-text-center"
                            >
                                Abbrechen
                            </Link>

                            <Button type="submit" disabled={isSubmitting} label="Speichern" />
                        </div>
                        <div className="d-fluid p-mt-6">
                            {organisationId && remove && (
                                <Authorized entityId={organisationId}>
                                    <Button
                                        className="p-mt-6 p-button-danger p-button-outlined p-text-center"
                                        onClick={remove}
                                        label="Löschen"
                                    />
                                </Authorized>
                            )}
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};
