import { array, object, number, string } from "yup";

const storeValidationSchema = object({
    name: string().required("Bitte geben Sie Ihrer Filiale einen Namen"),
    location: object({
        coordinates: array().of(number().required("Bitte geben Sie eine gültige Adresse an")),
    }),
    address: object({
        street: string().required("Bitte geben Sie Straße und Hausnr. an"),
        zip: string().required("Bitte geben Sie die Postleitzahl an"),
        city: string().required("Bitte geben Sie einen Ort an"),
    }),
    // email: string().email("Bitte geben Sie eine gültige E-Mail-Adresse ein"),
    phone: string().required("Bitte geben Sie eine Telefonnummer an"),
});

export { storeValidationSchema };
